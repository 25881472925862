import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import MainMenu from "@/views/MainMenu.vue";
import ToolAdder from "@/components/tool-adder.vue";
import ToolPage from "@/views/ToolPage.vue";
import Profile from "@/views/Profile.vue";
import GeneralMenu from "@/views/GeneralMenu.vue";
import UseCaseAdder from "@/components/use-case-adder.vue";
import CloudEquivalents from "@/views/CloudEquivalence.vue";
Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/tools",
    name: "tool-menu",
    component: MainMenu,
  },
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },
  {
    path: "/cinderella",
    name: "Cinderella",
    component: MainMenu,
  },
  {
    path: "/tool-adder",
    name: "Tool Adder",
    component: ToolAdder,
  },
  {
    path: "/tools/:toolId",
    name: "Tool",
    component: ToolPage,
    // props: { toolId: "" },
  },
  {
    path: "/use-case-adder",
    name: "UC Adder",
    component: UseCaseAdder,
  },
  {
    path: "/me",
    name: "Profile",
    component: Profile,
  },
  {
    path: "/",
    name: "Menu",
    component: GeneralMenu,
  },
  {
    path: "/cloud-equivs",
    name: "CloudEquivalents",
    component: CloudEquivalents,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
