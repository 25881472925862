import { logSearchTerm } from "@/analytics/search-analytics";
import { database } from "@/firebase.setup";
import { ServerlessService } from "@/types/serverless-types";
import { addDoc, collection, doc, getDoc, getDocs } from "firebase/firestore";

export const searchForNameByTerm = (
  searchTerm: string,
  tools: ServerlessService[]
): ServerlessService[] => {
  logSearchTerm(searchTerm);
  const newServices: ServerlessService[] = [];
  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  let lowerCase: string;

  tools.map((tool: ServerlessService) => {
    lowerCase = tool.name.toLowerCase();
    if (lowerCase.includes(lowerCaseSearchTerm)) {
      newServices.push(tool);
    }
  });

  return newServices;
};

export const fetchAndSearchByTerm = async (
  searchTerm: string
): Promise<ServerlessService[]> => {
  logSearchTerm(searchTerm);
  console.log("fetching");
  const listOfTools: ServerlessService[] = [];
  let docData: ServerlessService;
  const lowerCaseSearchTerm = searchTerm.toLowerCase();
  let lowerCase: string;

  const querySnapshot = await getDocs(collection(database, "serverless-tools"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    docData = doc.data() as ServerlessService;
    docData.fbId = doc.id;
    lowerCase = docData.name.toLowerCase();
    if (lowerCase.includes(lowerCaseSearchTerm)) {
      listOfTools.push(docData);
    }
  });

  return listOfTools;
};
