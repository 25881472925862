













































































































































import Vue from "vue";
export default Vue.extend({
  name: "ToolSubMenu",
  props: ["toolData"],
  data() {
    return {
      selectedSubMenu: 0,
    };
  },
  methods: {
    callNavigateToToolPage(fbId: string) {
      this.$emit("navigateToolPage", fbId);
    },
    goToOverview() {
      this.selectedSubMenu = 0;
    },
    goToRelatedTo() {
      this.selectedSubMenu = 1;
    },
  },
});
