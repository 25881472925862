































































import {
  fetchSingleServerlessTool,
  fetchToolUseCasesFromFB,
} from "@/services/tool-fb-service";
import { ServerlessService, UseCase } from "@/types/serverless-types";
import { logCloudEquivalentClicked } from "@/analytics/serverless-tool-analytics";
import ToolSubMenu from "@/components/tool-sub-menu.vue";
import Vue from "vue";
import UseCaseCard from "@/components/use-case-card.vue";
export default Vue.extend({
  name: "ToolPage",
  components: { UseCaseCard, ToolSubMenu },
  data() {
    return {
      toolData: { providerEquivalents: {} } as ServerlessService,
      allUseCases: [] as UseCase[],
    };
  },
  created() {
    console.log("Tool id: ", this.$route.params.toolId);
    this.getServerlessToolById();
    this.getUseCasesFromId();
  },
  methods: {
    async getServerlessToolById() {
      const resp = await fetchSingleServerlessTool(this.$route.params.toolId);
      console.log("resp: ", resp);
      this.toolData = resp;
    },
    async getUseCasesFromId() {
      console.log("fetching tool use case");
      const resp = await fetchToolUseCasesFromFB(this.$route.params.toolId);
      this.allUseCases = resp;
    },
    async navigateToToolPage(fbId: string): Promise<void> {
      logCloudEquivalentClicked(fbId, this.toolData.fbId);
      this.$router.push({
        name: "Tool",
        params: { toolId: fbId },
      });

      await this.getServerlessToolById();
    },
  },
});
