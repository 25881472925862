





































































































import Vue from "vue";
export default Vue.extend({
  name: "GeneralMenu",
  methods: {
    goToToolMenu() {
      this.$router.push({ path: "/tools" });
    },
    goToUseCases() {
      this.$router.push({ name: "MainMenu" });
    },
    goToCinderella() {
      this.$router.push({ name: "MainMenu" });
    },
    goToCloudEquivalents() {
      this.$router.push({ name: "CloudEquivalents" });
    },
  },
});
