import { analytics, database } from "@/firebase.setup";
import { ServerlessPageResult } from "@/types/fb-serverless-types";
import { ServerlessService, UseCase } from "@/types/serverless-types";
import {
  addDoc,
  collection,
  doc,
  getDoc,
  getDocs,
  setDoc,
  orderBy,
  query,
  Query,
  DocumentData,
  limit,
  startAfter,
} from "firebase/firestore";

export const uploadServerlessToolToFB = async (
  newTool: ServerlessService
): Promise<void> => {
  await addDoc(collection(database, "serverless-tools"), newTool);
};

export const uploadToolUseCaseToFB = async (
  newUsecase: UseCase,
  toolId: string
): Promise<void> => {
  console.log("adding to ", toolId);
  console.log(newUsecase);
  await addDoc(
    collection(database, `serverless-tools/${toolId}/use-cases`),
    newUsecase
  );
};

export const fetchToolUseCasesFromFB = async (
  toolId: string
): Promise<UseCase[]> => {
  console.log("fetching tool use cases");
  // fetch the document and a sub collection
  const listOfTools: UseCase[] = [];
  let docData: UseCase;
  const querySnapshot = await getDocs(
    collection(database, `serverless-tools/${toolId}/use-cases`)
  );
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " use case => ", doc.data());
    docData = doc.data() as UseCase;
    docData.fbId = doc.id;
    listOfTools.push(docData);
  });

  return listOfTools;
};

export const fetchFirstToolPage = async (): Promise<ServerlessPageResult> => {
  // get the paginated tools
  const pageLimit = 20;
  const toolsRef = collection(database, "serverless-tools");

  const toolQuery = query(toolsRef, orderBy("name"), limit(pageLimit));
  const querySnapshot = await getDocs(toolQuery);

  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  console.log("last", lastVisible);
  const listOfTools: ServerlessService[] = [];
  let docData: ServerlessService;
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    docData = doc.data() as ServerlessService;
    docData.fbId = doc.id;
    listOfTools.push(docData);
  });

  const next = query(
    toolsRef,
    orderBy("name"),
    startAfter(lastVisible),
    limit(pageLimit)
  );

  return {
    nextQuery: next,
    tools: listOfTools,
    lastVisibleId: lastVisible.id,
  };
};

export const fetchNextToolPage = async (
  nextPage: any // Argument of type 'QuerySnapshot<DocumentData>' is not assignable to parameter of type 'Query<DocumentData>'
): Promise<ServerlessPageResult> => {
  // ServerlessPageResult
  // get the paginated tools
  const pageLimit = 20;
  const toolsRef = collection(database, "serverless-tools");

  console.log("next page: ", nextPage);

  const querySnapshot = await getDocs(nextPage);

  const lastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
  console.log("last", lastVisible);
  const listOfTools: ServerlessService[] = [];
  let docData: ServerlessService;
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    // console.log(doc.id, " => ", doc.data());
    docData = doc.data() as ServerlessService;
    docData.fbId = doc.id;
    listOfTools.push(docData);
  });

  const next = query(
    toolsRef,
    orderBy("name"),
    startAfter(lastVisible),
    limit(pageLimit)
  );

  return {
    nextQuery: next,
    tools: listOfTools,
    lastVisibleId: lastVisible.id,
  };
};

export const fetchServerlessToolsFromFB = async (): Promise<
  ServerlessService[]
> => {
  console.log("fetching serverless tools");
  const listOfTools: ServerlessService[] = [];
  let docData: ServerlessService;
  const querySnapshot = await getDocs(collection(database, "serverless-tools"));
  querySnapshot.forEach((doc) => {
    // doc.data() is never undefined for query doc snapshots
    console.log(doc.id, " => ", doc.data());
    docData = doc.data() as ServerlessService;
    docData.fbId = doc.id;
    listOfTools.push(docData);
  });

  return listOfTools;
};

export const fetchSingleServerlessTool = async (
  key: string
): Promise<ServerlessService> => {
  console.log("fetch single serverless tool");

  const querySnapshot = doc(database, "serverless-tools", key);

  const docSnap = await getDoc(querySnapshot);
  if (docSnap.exists()) {
    console.log("Document data: ", docSnap.data());
    const docData = docSnap.data() as ServerlessService;
    docData.fbId = docSnap.id;
    return docData;
  }
  return {
    name: "",
    serviceType: "",
    cloudProvider: "",
    providerEquivalents: {
      aws: { serviceName: "", fbId: "" },
      gcp: { serviceName: "", fbId: "" },
      azure: { serviceName: "", fbId: "" },
    },
    pricing: "",
    languages: [],
    useCases: [],
    description: "",
    imageUrl: "",
    fbId: "",
    previewDescription: "",
  };
};
