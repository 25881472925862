








































































import {
  CloudProviderEquivalent,
  CloudRef,
  ServerlessService,
  UseCase,
} from "@/types/serverless-types";
import {
  uploadServerlessToolToFB,
  uploadToolUseCaseToFB,
} from "@/services/tool-fb-service";
import ToolFinder from "./tool-finder.vue";
import ToolSelector from "./tool-selector.vue";
import Vue from "vue";
export default Vue.extend({
  name: "UseCaseAdder",
  components: {
    ToolFinder,
    ToolSelector,
  },
  data() {
    return {
      addTool: { providerEquivalents: {} } as ServerlessService,
      providerEquivalents: {} as CloudProviderEquivalent,
      selectedTool: { providerEquivalents: {} } as ServerlessService,
      additionalTools: [] as CloudRef[],
      newUsecase: {} as UseCase,
    };
  },
  methods: {
    uploadNewUseCase() {
      console.log("uploading the tool now");
      uploadToolUseCaseToFB(this.newUsecase, this.selectedTool.fbId);
    },
    selectToolToAddUC(newTool: ServerlessService) {
      this.selectedTool = newTool;
      // const newTools: CloudRef[] = [];
      // newTools.push({ serviceName: newTool.name, fbId: newTool.fbId });
      // this.newUsecase.additionalTools = newTools;
    },
    addAdditionalTool(newTool: ServerlessService) {
      // const addTools = this.additionalTools;
      // addTools.push(newTool);
      // this.additionalTools = addTools;
      const addTools: CloudRef[] = [];
      addTools.push({ serviceName: newTool.name, fbId: newTool.fbId });
      this.additionalTools = addTools;
      this.newUsecase.additionalTools = addTools;
    },
  },
});
