

































import { ServerlessService } from "@/types/serverless-types";
import { fetchAndSearchByTerm } from "@/services/search-service";
import Vue from "vue";
export default Vue.extend({
  name: "ToolSelector",
  data() {
    return {
      searchResults: [] as ServerlessService[],
      searchText: "",
      selectedTool: { providerEquivalents: {} } as ServerlessService,
      mostRecentId: 0 as number,
      isToolSelected: false,
    };
  },
  watch: {
    searchText(newVal, oldVal) {
      if (newVal !== oldVal) {
        clearTimeout(this.mostRecentId);

        const toId = setTimeout(() => {
          console.log("searching now for ", newVal, " : old val was ", oldVal);
          this.callSearch();
        }, 2000);

        this.mostRecentId = toId;
      }
    },
  },
  methods: {
    async callSearch() {
      this.searchResults = await fetchAndSearchByTerm(this.searchText);
    },
    selectTool(newTool: ServerlessService) {
      console.log("select tool now", newTool);
      this.selectedTool = newTool;
      this.isToolSelected = true;
      this.$emit("addAdditionalTool", newTool);
    },
  },
});
