



























































































































import { ServerlessService } from "@/types/serverless-types";
import { uploadServerlessToolToFB } from "@/services/tool-fb-service";
import Vue from "vue";
export default Vue.extend({
  name: "ToolAdder",
  data() {
    return {
      addTool: { providerEquivalents: {} } as ServerlessService,
      providerEquivalents: {} as any,
      selectedProvider: "" as any,
    };
  },
  methods: {
    uploadNewTool() {
      uploadServerlessToolToFB(this.addTool);
    },
  },
});
