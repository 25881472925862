import { firebaseConfig } from "./firebase.config";
import firebase from "firebase/compat/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
// import "firebase/compat/firestore";
import "firebase/auth";

firebase.initializeApp(firebaseConfig);

export const database = getFirestore();

export const analytics = getAnalytics();
