




































































import { ServerlessService } from "@/types/serverless-types";
import { fetchAndSearchByTerm } from "@/services/search-service";
import Vue from "vue";
export default Vue.extend({
  name: "ToolFinder",
  data() {
    return {
      selectedTool: { providerEquivalents: {} } as ServerlessService,
      toolSearchResults: [] as ServerlessService[],
      searchTerm: "" as string,
    };
  },
  methods: {
    async performSearchForTool() {
      this.toolSearchResults = await fetchAndSearchByTerm(this.searchTerm);
    },
    callSelectTool(newTool: ServerlessService) {
      this.selectedTool = newTool;
      this.$emit("selectTool", newTool);
    },
    async onEnterKeyPress() {
      this.performSearchForTool();
    },
  },
});
