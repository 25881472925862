








































































































import Vue from "vue";
import ServerlessToolCard from "@/components/serverless-tool-card.vue";
import {
  fetchFirstToolPage,
  fetchNextToolPage,
  fetchServerlessToolsFromFB,
} from "@/services/tool-fb-service";
import { ServerlessService } from "@/types/serverless-types";
// import Multiselect from "vue-multiselect";
// Multiselect
import { searchForNameByTerm } from "@/services/search-service";
import { DocumentData, Query, QuerySnapshot } from "@firebase/firestore";
export default Vue.extend({
  name: "MainMenu",
  components: { ServerlessToolCard },
  data() {
    return {
      tools: [] as ServerlessService[],
      initialTools: [] as ServerlessService[],
      isFilterShowing: true,
      filteredTools: [] as ServerlessService[],
      cloudProviderFilter: "all",
      selectedUseCases: [],
      useCaseOptions: [{ name: "API" }, { name: "ETL" }],
      isFilterActive: false,
      searchTerm: "",
      nextPage: {} as Query<DocumentData>,
    };
  },
  created() {
    console.log("created ");
    // fetchServerlessToolsFromFB();
    this.getAllServerlessTools();
    console.log("done calling");
  },
  mounted() {
    //
    this.getNextToolPage();
  },
  beforeMount() {
    //
  },
  watch: {
    cloudProviderFilter() {
      console.log("changing cloud filter");
      this.updateToolsByFilter();
      if (this.cloudProviderFilter === "all") {
        this.isFilterActive = false;
      }
    },
  },
  methods: {
    async getAllServerlessTools() {
      console.log("Getting all serverless in the main menu");
      const allTools = await fetchServerlessToolsFromFB();
      this.tools = allTools;
      this.initialTools = allTools;
      const resp = await fetchFirstToolPage();
      console.log("first resp tool");
      console.log(resp);
      const respDos = await fetchNextToolPage(resp.nextQuery);
      console.log("respDos ", respDos);
      // this.nextPage = respDos.nextQuery;
    },
    toggleFiltersDisplay() {
      this.isFilterShowing = !this.isFilterShowing;
    },
    updateToolsByFilter() {
      let newFilter: ServerlessService[] = [];
      this.initialTools.map((tool: ServerlessService) => {
        if (
          tool.cloudProvider === "all" ||
          tool.cloudProvider === this.cloudProviderFilter.toLowerCase()
        ) {
          newFilter.push(tool);
        }
      });
      this.filteredTools = newFilter;
      this.isFilterActive = true;
    },
    getNextToolPage() {
      window.onscroll = async () => {
        console.log(" scroll");
        let bottomOfWindow =
          document.documentElement.scrollTop + window.innerHeight ===
          document.documentElement.offsetHeight;
        if (bottomOfWindow) {
          console.log("bottom");
          // axios.get(`https://randomuser.me/api/`).then((response) => {
          //   this.users.push(response.data.results[0]);
          // });
          const resp = await fetchNextToolPage(this.nextPage);
          //this.nextPage = resp.nextQuery;
          console.log("resp is on scrol", resp);
          // this.tools.concat(resp.tools);
        }
      };
    },
    performSearch() {
      if (this.isFilterActive) {
        this.filteredTools = searchForNameByTerm(
          this.searchTerm,
          this.filteredTools
        );
      } else {
        this.tools = searchForNameByTerm(this.searchTerm, this.initialTools);
      }
    },
    async onEnterKeyPress() {
      this.performSearch();
    },
  },
});
