import { analytics } from "@/firebase.setup";
import { logEvent } from "@firebase/analytics";

export const logToolClicked = (toolId: string): void => {
  console.log("logging tool with id ", toolId, " clicked");
  logEvent(analytics, "select_content", {
    content_type: "serverless-tool",
    item_id: toolId,
  });
};

export const logCloudEquivalentClicked = (
  toolId: string,
  fromToolId: string
): void => {
  logEvent(analytics, "select_content", {
    content_type: `serverless-tool-${fromToolId}`,
    item_id: toolId,
  });
};
