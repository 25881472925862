







import NavBar from "./components/nav-bar.vue";
import Vue from "vue";
export default Vue.extend({
  name: "App",
  components: { NavBar },
});
