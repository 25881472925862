










































import { logToolClicked } from "@/analytics/serverless-tool-analytics";
import Vue from "vue";
export default Vue.extend({
  name: "ServerlessToolCard",
  props: ["toolInfo"],
  methods: {
    goToToolPage() {
      console.log("go to tool page");
      logToolClicked(this.toolInfo.fbId);
      this.$router.push({
        name: "Tool",
        params: { toolId: this.toolInfo.fbId },
      });
    },
  },
});
