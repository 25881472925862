






























import { CloudRef, ServerlessService } from "@/types/serverless-types";
import Vue from "vue";
export default Vue.extend({
  name: "UseCaseCard",
  props: ["useCaseInfo"],
  data() {
    return {
      addTools: [] as CloudRef[],
    };
  },
  created() {
    this.setAddTools();
  },
  methods: {
    setAddTools() {
      this.addTools = this.useCaseInfo.additionalTools;
    },
    goToToolPage(fbId: string) {
      this.$router.push({
        name: "Tool",
        params: { toolId: fbId },
      });
    },
  },
});
